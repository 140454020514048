@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.editGames {
  max-width: 100%;
  overflow: hidden;

  &__header {
    @include tablet {
      @include flexbox(space-between);
    }
    &-text {
      @include h2;
    }

    &-dropdown {
      @include dropdown;

      &-select,
      &-item {
        width: 9.5rem;
      }
    }
  }

  &__block {
    max-height: 550px;
    position: relative;
    margin-top: 1.5rem;
    overflow-x: auto;
    overflow-y: auto;
    scrollbar-width: none;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    z-index: 1;

    &-title {
      @include tableHeader(1.25rem);
      position: sticky;
      top: 0;
      z-index: 2;
    }

    &-headers {
      background-color: $white;
      height: 2rem;
      position: sticky;
      top: 2rem;
      z-index: 2;

      @include responsive-table {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }

    &-row {
      border-top: 1px solid $black;

      @include responsive-table {
        padding-top: 0.75rem;
        @include flexbox(space-between, center, row, wrap);
        margin-bottom: 0.625em;
        font-size: 0.875rem;
      }

      @include desktop {
        height: 2rem;
      }
    }

    &-box {
      text-align: left;
      padding: 0.25rem;
      @include bigp;

      @include responsive-table {
        @include flexbox(flex-start, flex-start);

        &::before {
          content: attr(data-label);
          float: left;
          font-weight: bold;
          text-transform: uppercase;
          width: 6rem;
          flex-shrink: 0;
          white-space: nowrap;
        }

        &[data-label="Edit"]::before {
          content: "";
          width: 0;
        }
      }

      &-date {
        width: 100%;

        @include tablet {
          width: 50%;
        }

        @include desktop {
          width: 10%;
          text-align: center;
        }
      }

      &-time {
        width: 100%;
        & input {
          @include tablet {
            width: 4rem;
          }
        }

        @include tablet {
          width: 45%;
        }

        @include desktop {
          width: 5%;
          text-align: center;
        }
      }

      &-complete {
        width: 100%;

        @include tablet {
          width: 50%;
        }

        @include desktop {
          width: 5%;
          text-align: center;
        }
      }

      &-type {
        width: 100%;

        @include tablet {
          width: 45%;
        }

        @include desktop {
          width: 10%;
          text-align: center;
        }
      }

      &-team {
        width: 100%;

        & input {
          @include desktop {
            width: 10rem;
          }
        }
        &-score {
          width: 100%;
          & input {
            @include tablet {
              width: 3rem;
            }
            @include desktop {
              width: 2rem;
            }
          }
          @include tablet {
            width: 45%;
          }
          @include desktop {
            width: 7.5%;
            text-align: center;
          }
        }
        @include tablet {
          width: 50%;
        }

        @include desktop {
          width: 10%;
          text-align: center;
        }
      }

      &-notes {
        width: 100%;

        & textarea {
          @include input;
          height: 5rem;

          @include tablet {
            width: 100%;
            height: 3rem;
          }
        }

        @include desktop {
          text-align: center;
          width: 15%;
        }
      }

      &-result {
        width: 100%;

        @include desktop {
          width: 10%;
          text-align: center;
        }
      }

      &-icons {
        @include responsive-table {
          @include flexbox(space-between);
          padding-top: 0.75rem;
          width: 100%;
        }

        @include desktop {
          width: 10%;
          text-align: center;
        }
      }

      &-icon {
        cursor: pointer;
        @include responsive-table {
          height: 1.5rem;
          margin: 0 auto;
        }

        @include tablet {
          height: 1.25rem;
        }

        &-edit,
        &-check {
          color: $omr-darkblue;
          margin-right: 1.75rem;
        }

        &-delete,
        &-x {
          color: $omr-red;
        }
      }

      & input,
      select {
        @include input;
      }
    }
  }
}
