@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.background {
  height: 90%;
  min-height: 90vh;
  padding-bottom: 20rem;
  position: relative;
}

.scoreSked {
  background-color: $white;
  max-width: 100%;
  overflow: hidden;
  padding: 0rem 1rem 2rem;

  &__header {
    @include flexbox;
    &-text {
      @include h1;

      @include desktop {
        width: 90%;
        margin: 2rem auto;
        max-width: 1280px;
      }
    }

    &-dropdown {
      @include dropdown;

      &-select,
      &-item {
        width: 9.5rem;
      }
    }
  }

  @include tablet {
    padding: 0rem 2rem 2rem;
  }
}
