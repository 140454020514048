@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.header {
  background-color: $omr-darkblue;

  &__container {
    @include flexbox(center, center, column);

    @include tablet {
      @include flexbox(space-between, center, row);
      margin: 0 2rem;
    }

    @include desktop {
      margin: 0 auto;
      width: 90%;
      max-width: 1280px;
    }
  }

  &__league-logo {
    @include flexbox(center);
    height: 9rem;
    width: 100%;

    &-img {
      height: 7.5rem;
    }

    @include tablet {
      @include flexbox(flex-start);
    }
  }

  &__team-logos {
    @include flexbox(space-around);
    background-color: white;
    padding: 1rem 0;
    width: 100%;
    box-shadow: 0 20px 10px -15px rgba(0, 0, 0, 0.5) inset,
      0 -20px 10px -15px rgba(0, 0, 0, 0.5) inset;

    &-img {
      height: 12vw;
    }

    @include tablet {
      border-radius: 5px;
      box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.65) inset;
      max-width: 35rem;

      &-img {
        height: 3.5rem;
      }
    }
  }
}
