@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.footer {
  background-color: $black;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20rem;

  &__container {
    padding: 0 1rem;

    @include tablet {
      @include flexbox(flex-start, flex-start);
      padding: 0.5rem 2rem;
    }

    @include desktop {
      margin: 0 auto;
      padding: 0 4rem;
      max-width: 1280px;
    }
  }

  &__about {
    @include tablet {
      width: 75%;
    }
  }

  &__header {
    @include h2;
    color: $white;
  }

  &__text {
    @include bigp;
    color: $white;
  }

  &__socials {
    &-img {
      padding-right: 1rem;
      height: 2rem;
      width: 2rem;

      @include tablet {
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    @include tablet {
      padding-left: 3rem;
      width: 25%;
    }
  }

  &__admin {
    padding: 2rem 1rem;
    
    @include tablet {
      padding: 2rem;
    }

    @include desktop {
      padding: 2rem 4rem;
      max-width: 1280px;
      margin: 0 auto;
    }

    &-link {
      color: $white;
      text-decoration: none;
      font-weight: 600;
    }
  }
}
