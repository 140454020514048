@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.background {
  height: 90%;
  min-height: 90vh;
  padding-bottom: 20rem;
  position: relative;
}

.home-main {
  background-color: $white;
  &__stand-announce {
    @include tablet {
      @include flexbox(space-between, flex-start, row-reverse);
      padding: 0 2rem;
    }

    @include desktop {
      margin: 0 auto;
      width: 90%;
      max-width: 1280px;
    }
  }

  & .hero {
    margin: 0 auto;
  }
}
