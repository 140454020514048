@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.standings {
  padding: 1.5rem 0.875rem;
  max-width: 100%;
  overflow-x: auto;

  &__table {
    width: 100%;
    border-collapse: collapse;

    &-title {
      @include tableHeader(1.25rem);

      @include desktop {
        @include tableHeader(1.375rem, 1.75rem);
      }
    }

    &-headers th {
      @include bigp;
      padding: 0.5rem 0;
      font-weight: 600;
    }

    &-row {
      @include bigp;
    }

    &-row:nth-of-type(odd) {
      background-color: $omr-grey;
    }

    &-team {
      @include flexbox(space-around);
      padding-left: 0.5rem;
    }

    &-name {
      padding: 0 0.5rem 0 1rem;
    }

    &-img {
      height: 1.75rem;
      padding: 0.25rem 0;
    }

    &-wltp {
      padding: 0 0.5rem;
      text-align: center;
    }
  }

  @include tablet {
    padding: 1.5rem 0 1.5rem 0.875rem;
    width: 50%;
  }

  @include desktop {
    width: 35%;
  }
}
