@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.announcements {
  padding: 0 0.875rem 0.75rem;
  max-width: 100%;

  &__title {
    @include tableHeader(1.25rem);

    @include desktop {
      @include tableHeader(1.375rem, 1.75rem);
    }
  }

  &__box {
    @include flexbox(space-between, flex-start, row, wrap);
    font-size: 0.875rem;
    padding: 1rem 0.5rem;
    border-bottom: 2px solid $omr-grey;

    &:last-of-type {
        border-bottom: none;
    }

    &-title {
      @include bigp;
      width: 60%;
      font-weight: 600;
    }

    &-date {
      @include bigp;
      width: 35%;
      font-weight: 600;
      text-align: right;
    }

    &-body {
      @include bigp;
      width: 100%;
      padding-top: 0.75rem;
    }
  }

  @include tablet {
    padding: 1.5rem 0.875rem 0.75rem 0;
    width: 50%;
  }

  @include desktop {
    width: 60%;
  }
}
