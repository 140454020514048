@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.gdh {
  padding-top: 1rem;

  &__game-info {
    @include flexbox(center, center, column);

    &-title {
      @include h1;
      margin: 1rem 0;

      @include tablet {
        margin: 1rem 0 0.5rem;;
      }
    }

    &-block {
      @include flexbox(center, center);
    }
    &-logo {
      height: 3.25rem;
    }

    &-score {
      @include h2;
      padding: 0 1rem;

      @include desktop {
        margin: 0.75rem 0;
      }
    }

    &-date-time {
      @include h3;

      @include tablet {
        margin: 0.5rem 0;
      }
    }

    &-text-notes {
        @include bigp;
    }
  }

  @include tablet {
    padding-bottom: 1rem;
  }
}
