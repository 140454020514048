@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.background {
    height: 90%;
    min-height: 90vh;
    position: relative;
    padding-bottom: 20rem;
}

.admin-main {
  background-color: $omr-grey;
  padding: 0 1rem 2rem;

  @include tablet {
    padding: 0 3rem 2rem;
  }

  @include desktop {
    padding: 0 4rem 2rem;
  }

  & .teamHeader {
    padding: 1rem 0 0;
  }
}

