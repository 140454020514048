@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.scorebar {
  @include flexbox(center);
  height: 8.5rem;
  background-color: $omr-darkblue;

  &__button {
    background-color: $omr-darkblue;
    border: none;

    @include tablet {
      display: none;
    }

    &-img {
      height: 1.5rem;
    }
  }

  &__container {
    @include flexbox;
    overflow-x: scroll;
    overflow-y: visible;
    scroll-snap-type: x mandatory;
    white-space: nowrap;
    scrollbar-width: none;
    border-radius: 5px;
    height: 7rem;
    padding: 0 0.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    @include tablet {
      @include flexbox(space-between, center, row, nowrap, 1vw);
      padding: 0;
    }

    @include desktop {
      @include flexbox(space-around, center, row, nowrap, 4vw);
      padding: 0;
      height: 8rem;
    }
  }

  &__link {
    text-decoration: none;
    cursor: pointer;
    margin: 0 0.75rem;
  }

  &__box {
    @include flexbox(center, center, column);
    flex: 1 0 auto;
    position: relative;
    scroll-snap-align: center;
    background-color: $white;
    height: 7rem;
    min-width: 10rem;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.65) inset;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &-header {
      @include flexbox(center);
      @include tableHeader(0.875rem, 1.25rem);
      flex: 0 0 auto;
      width: 100%;
      padding: 0.25rem 0;
      border-radius: 8px 8px 0 0;
      box-shadow: 0 0px 8px rgba(0, 0, 0, 0.65);

      @include desktop {
        @include tableHeader(1rem, 1.375rem);
        border-radius: 8px 8px 0 0;
        padding: 0.25rem 0;
      }
    }

    &-body {
      @include flexbox(center, center);
      flex: 1 1 auto;

      &-img {
        height: 2.25rem;
      }

      &-text {
        @include biggerp;
        margin: 0.5rem;
        color: $black;

        @include desktop {
          font-size: 1.25rem;
          margin: 0.75rem;
        }
      }

      @include desktop {
        height: 100%;
      }
    }

    &-playoff {
      @include flexbox(center, center);
      @include bigp;
      font-weight: 600;
      position: relative;
      margin-top: auto;
      background-color: $omr-lightblue;
      color: $white;
      text-align: center;
      min-height: 1.5rem;
      width: 100%;
      padding-bottom: 0.12rem;
      text-decoration: none;
      border-radius: 0 0 8px 8px;
      box-shadow: 0 0px 8px rgba(0, 0, 0, 0.65);

      @include desktop {
        padding-bottom: 0;
      }
    }

    @include tablet {
      margin: auto;
      flex-grow: 1;
    }

    @include desktop {
      min-width: 12rem;
    }
  }

  @include tablet {
    @include flexbox(center, center, row, nowrap, normal);
    width: 100%;
  }

  @include desktop {
    height: 9rem;
  }
}
