@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.nav {
  @include flexbox(center);
  background-color: $omr-red;
  position: relative;

  &__toggle {
    color: $white;
    height: 1.5rem;
    padding: 0.5rem 0;

    @include tablet {
      display: none;
    }

    &-close {
      position: absolute;
      top: 0.75rem;
      left: 0.75rem;
      color: $white;
      height: 1.5rem;

      @include tablet {
        display: none;
      }
    }
  }

  &__links {
    @include flexbox(center, center, column);
    list-style: none;
    padding-inline-start: 0;
    font-size: 1.25rem;
    padding: 0.5rem 0;
    margin: 0;
    white-space: nowrap;

    &-text {
      @include biggerp;
      font-weight: 600;
      padding: 0.5rem 0;
      text-align: center;

      &:nth-of-type(2) {
        border-top: 2px solid $white;
        border-bottom: 2px solid $white;

        @include tablet {
          border-top: none;
          border-bottom: none;
          border-left: 2px solid $white;
          border-right: 2px solid $white;
          position: relative;
          z-index: 1;

          &:hover {
            border-left: 2px solid $omr-red;
            border-right: 2px solid $omr-red;
            transition: border-left 0.5s ease,
            border-right 0.1s ease;
          }
        }
      }

      @include tablet {
        padding: 0;
        visibility: visible;
      }
    }

    &-link {
      text-decoration: none;
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: $white;
      position: relative;
      z-index: 1;
      transition: color 0.5s ease-in-out, box-shadow 0.5s ease-in-out;

      @include tablet {
        padding: 0.5rem 2.5rem;

        &-ll {
            margin-right: -2px;
          }

        &-a {
          margin-left: -2px;
        }
      }

      @include desktop {
        padding: 0.5rem 5.5rem;
      }

      &:visited {
        color: $white;
      }

      &:hover {
        @include tablet {
          color: $omr-red;
          box-shadow: inset 0 -3rem 0 0 #ffffff;
        }
      }
    }

    @include tablet {
      @include flexbox(center);
      padding: 0.5rem 2rem;
    }
  }
  @include tablet {
    &.visible {
      display: flex;
    }
  }
}
