@use "./variables" as *;

@mixin responsive-table {
  @media (max-width: 1279px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin flexbox(
  $justify-content: flex-start,
  $align-items: center,
  $direction: row,
  $wrap: nowrap,
  $gap: 0,
  $display: flex
) {
  justify-content: $justify-content;
  align-items: $align-items;
  flex-direction: $direction;
  flex-wrap: $wrap;
  gap: $gap;
  display: $display;
}

@mixin tableHeader($font-size: 0.8125rem, $height: 1.5rem) {
  background-color: $omr-red;
  height: $height;
  padding: 0.25rem;
  text-align: center;
  border-radius: 5px 5px 0 0;
  font-family: $Rubik;
  font-size: $font-size;
  font-weight: 600;
  color: $white;
}

@mixin button {
  background-color: $omr-lightblue;
  color: $white;
  border: none;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
  font-family: $Rubik;
  font-size: 0.875rem;
  font-weight: 600;
  box-shadow: 5px 5px #0560ad;

  @include tablet {
    font-size: 1rem;
  }

  @include desktop {
    font-size: 1.125rem;
  }

  &:hover {
    background-color: #2095f8;
    cursor: pointer;
  }

  &:active {
    box-shadow: 0 0 #0560ad;
    transform: translateY(5px) translateX(5px);
    border-radius: 5px;
  }
}

@mixin input {
  @include bigp;
  border: none;
  height: 1.5rem;
}

@mixin dropdown {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  border: 2px solid black;
  height: 1.5rem;
  padding: 0.5rem;

  &-select {
    @include flexbox(space-between);
    @include bigp;
  }

  &-text {
    white-space: nowrap;
    padding: 0 0.5rem;
  }

  &-icon {
    flex-shrink: 0;
    padding-left: 0.75rem;
  }

  &-menu {
    @include flexbox(flex-start, center, column);
    position: absolute;
    top: 2.65rem;
    left: -0.15rem;
  }

  &-item {
    @include flexbox(center);
    cursor: pointer;
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    height: 1.5rem;
    padding: 0.5rem;
    background-color: $white;
    z-index: 20;
  }
}

@mixin h1 {
  font-family: $Rubik;
  font-size: 1.5rem;
  font-weight: 600;

  @include tablet {
    font-size: 2rem;
  }

  @include desktop {
    font-size: 2.5rem;
  }
}

@mixin h2 {
  font-family: $Rubik;
  font-size: 1.25rem;
  font-weight: 600;

  @include tablet {
    font-size: 1.75rem;
  }

  @include desktop {
    font-size: 2rem;
  }
}

@mixin h3 {
  font-family: $Rubik;
  font-size: 1rem;
  font-weight: 600;

  @include tablet {
    font-size: 1.25rem;
  }

  @include desktop {
    font-size: 1.5rem;
  }
}

@mixin teamh1 {
  font-family: $Rubik;
  font-size: 1.75rem;
  font-weight: 600;

  @include tablet {
    font-size: 2.25rem;
  }
}

@mixin columnheader {
  font-family: $Rubik;
  font-size: 1rem;
  font-weight: 600;

  @include tablet {
    font-size: 1.25rem;
  }
}

@mixin smallp {
  font-family: $Rubik;
  font-size: 0.75rem;
  font-weight: 400;

  @include desktop {
    font-size: 0.875rem;
  }
}

@mixin bigp {
  font-family: $Rubik;
  font-size: 0.875rem;
  font-weight: 400;

  @include tablet {
    font-size: 1.0625rem;
  }

  @include desktop {
    font-size: 1rem;
  }
}

@mixin biggerp {
  font-family: $Rubik;
  font-size: 1rem;
  font-weight: 400;

  @include tablet {
    font-size: 1.125rem;
  }

  @include desktop {
    font-size: 1.25rem;
  }
}
