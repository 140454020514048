@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.addAnnouncement {
  &__header {
    @include h2;
    margin: 0.5rem 0 1.5rem;
  }
  &__form {
    @include flexbox(space-between, center, row, wrap);
    @include biggerp;

    &-date {
      width: 100%;

      @include tablet {
        margin-bottom: 0;
        width: 40%;
      }

      @include desktop {
        width: 35%;
      }

      & span {
        vertical-align: top;
        margin-right: 0.75rem;

        @include desktop {
          margin-right: 0.8rem;
        }
      }
    }
    &-title {
      width: 100%;
      margin: 0.75rem 0;

      @include tablet {
        text-align: left;
        width: 60%;
      }

      & span {
        vertical-align: top;
        margin-right: 0.9rem;
      }

      &-input {
        @include input;
        width: 80%;

        @include tablet {
          width: 71.5%;
        }

        @include desktop {
          width: 71.8%;
        }
      }
    }
    &-body {
      width: 100%;

      &-input {
        @include input;
        width: 80%;
        height: 5rem;

        @include tablet {
          width: 82.75%;
        }
      }

      @include tablet {
        width: 100%;
      }

      & span {
        vertical-align: top;
        margin-right: 0.55rem;
      }
    }

    &-button {
      @include button;
      margin: 0.75rem auto;
      width: 90%;
      max-width: 15rem;

      @include tablet {
        width: 50%;
        margin-top: 0.75rem;
        margin-left: 3.5rem;
      }

      @include desktop {
        margin-left: 3.85rem;
      }
    }

    @include tablet {
      max-width: 600px;
    }
  }
}

// .react-datepicker {
//   &__input-container input {
//     @include input;
//     width: 6.5rem;

//     @include tablet {
//       width: 9rem;
//     }
//   }
//   &-popper {
//     z-index: 3 !important;
//   }
// }
