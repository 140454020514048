@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &__content {
    background: $white;
    padding: 1.5rem;
    border-radius: 5px;
    max-width: 20rem;
    margin: 0 1rem;
    z-index: 11;
  }

  &__cancel {
    @include button;
    background-color: $omr-red;
    box-shadow: none;
    margin-right: 1rem;

    &:hover {
      background-color: $omr-red;
    }
  }

  &__confirm {
    @include button;
    box-shadow: none;

    &:hover {
      background-color: $omr-lightblue;
    }
  }
}
