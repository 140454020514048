@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.background {
    height: 90%;
    min-height: 90vh;
    position: relative;
    padding-bottom: 20rem;
    background-color: $omr-grey
}

.login {
  @include flexbox(center, flex-start, column);
  padding: 3rem 0;
  max-width: 20rem;
  margin: 0 auto;

  &__header {
    margin: 0;
    padding: 1.5rem 0;
    font-size: 2rem;
  }

  &__form {
    @include flexbox(center, center, column);
    width: 100%;
  }

  &__label {
    width: 100%;
  }

  &__label p {
    margin: 0;
    padding: 1.25rem 0 0.5rem;
    font-size: 1.25rem;
  }

  &__input {
    @include input;
    width: 100%;
    height: 2rem;
  }

  &__button {
    @include button;
    align-self: flex-end;
    margin-top: 2rem;
    font-size: 1.25rem;
  }
}
