@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.goalies {
  padding: 1.5rem 0;

  &__table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    z-index: 1;

    &-title {
      @include tableHeader(1.25rem);
      top: 0;
      z-index: 2;
    }

    &-headers {
      background-color: $white;
      height: 2rem;
      top: 2rem;
      z-index: 2;
    }

    &-row {
      height: 2rem;
    }

    &-row:nth-of-type(odd) {
      background-color: $omr-grey;
    }

    &-box {
      text-align: center;
      padding: 0.25rem;
    }
  }
}
