@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.schedule {
  padding: 0 0.875rem 1.5rem;
  max-width: 100%;
  overflow-x: auto;

  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2.5rem;

    &-title {
      @include tableHeader(1.25rem);

      @include tablet {
        @include tableHeader(1.5rem, 1.75rem);
      }
    }

    &-headers th {
      @include columnheader;
      padding: 0.5rem 0;
    }

    &-row {
      @include bigp;
    }

    &-row:nth-of-type(odd) {
      background-color: $omr-grey;
    }

    &-date {
      padding: 0.5rem 0 0.5rem 0.25rem;

      @include tablet {
        text-align: center;
      }
    }

    &-opp {
      text-align: center;
      padding: 0.5rem;

      &:last-of-type {
        padding-right: 0.5rem;
      }
    }

    &-res {
      text-align: right;
      padding: 0.5rem 0.25rem 0.5rem 0;

      @include tablet {
        text-align: center;
      }

      &-link, &-link:visited {
        color: $omr-darkblue;
        text-decoration: none;
      }
    }
  }

  @include tablet {
    padding: 0.75rem 0;
  }

  @include desktop {
    width: 45%;
    padding: 0.75rem 0;
  }
}
