@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.teamHeader {
  padding: 0.75rem 0.875rem 0;

  &__title {
    @include flexbox;

    &-logo {
      height: 4rem;
    }

    &-teamname {
      @include teamh1;
      margin: 1.5rem 0 1.5rem 1rem;
    }
  }

  @include tablet {
    padding: 1.5rem 0;
  }
}
