@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.addGames {
  &__header {
    @include h2;
    margin: 0.5rem 0 1.5rem;
  }
  &__form {
    @include flexbox(space-between, center, row, wrap);
    @include biggerp;

    &-type {
      @include flexbox;
      width: 55%;
      margin-top: 0.75rem;

      & span {
        display: inline-block;
        width: 4.25rem;

        @include tablet {
          width: 5rem;
        }
      }

      &-input {
        @include input;
        width: 7.75rem;

        @include tablet {
          width: 10.25rem;
        }
      }
    }

    &-season {
      @include flexbox;
      width: 45%;
      margin-top: 0.75rem;

      @include tablet {
        width: 40%;
      }

      & span {
        width: 4.5rem;

        @include tablet {
          width: 5rem;
        }
      }

      &-input {
        @include input;
        width: 5.75rem;
      }
    }

    &-date {
      @include flexbox;
      margin-top: 0.75rem;
      width: 55%;

      @include tablet {
        margin-bottom: 0;
      }

      & span {
        display: inline-block;
        width: 4.25rem;

        @include tablet {
          width: 5rem;
        }
      }

      &-input {
        @include input;
        width: 7.5rem;

        @include tablet {
          width: 10rem;
        }
      }
    }

    &-time {
      width: 45%;
      margin-top: 0.75rem;

      @include tablet {
        width: 40%;
      }

      & span {
        display: inline-block;
        width: 4.5rem;

        @include tablet {
          width: 5rem;
        }
      }

      &-input {
        @include input;
        width: 5.5rem;
      }
    }

    &-arena {
      width: 100%;
      margin-top: 0.75rem;

      & span {
        display: inline-block;
        width: 4.25rem;

        @include tablet {
          width: 5rem;
        }
      }

      &-input {
        @include input;
        width: 81%;
      }
    }

    &-team {
      width: 100%;
      margin-top: 0.75rem;

      & span {
        display: inline-block;
        width: 4.25rem;

        @include tablet {
          width: 5rem;
        }
      }

      &-input {
        @include input;
        width: 81%;
      }
    }

    &-notes {
      width: 100%;
      margin-top: 0.75rem;

      &-input {
        @include input;
        width: 80%;
        height: 5rem;
      }

      @include tablet {
        width: 100%;
      }

      & span {
        display: inline-block;
        vertical-align: top;
        width: 4.25rem;

        @include tablet {
          width: 5rem;
        }
      }
    }

    &-button {
      @include button;
      margin: 0.75rem auto;
      width: 90%;
      max-width: 15rem;

      @include tablet {
        width: 60%;
        margin-top: 0.75rem;
      }
    }

    @include tablet {
      max-width: 28rem;
    }
  }
}
