@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.background {
  height: 90%;
  min-height: 90vh;
  padding-bottom: 20rem;
  position: relative;
}

.team-main {
  background-color: $white;
  min-height: 77vh;

  &__header {
    @include tablet {
      @include flexbox(space-between, center);
    }

    @include desktop {
      max-width: 1280px;
      width: 90%;
      margin: 0 auto;
    }
    &-button {
      @include button;
      margin: 0.75rem 0.875rem 1.5rem;
    }
  }

  @include tablet {
    padding: 0 2.25rem;
  }

  @include desktop {
    padding: 0;
    &__ros-sked {
      @include flexbox(space-between, flex-start);
      max-width: 1280px;
      width: 90%;
      margin: 0 auto;
    }
  }
}
