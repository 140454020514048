@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.roster {
  padding: 0.75rem 0.875rem 0;
  max-width: 100%;
  overflow-x: auto;

  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2.5rem;

    &-title {
      @include tableHeader(1.25rem);

      @include tablet {
        @include tableHeader(1.5rem, 1.75rem);
      }
    }

    &-headers th {
      @include columnheader;
      padding: 0.5rem 0;
      cursor: pointer;
    }

    &-row {
      @include bigp;
    }

    &-row:nth-of-type(odd) {
      background-color: $omr-grey;
    }

    &-name {
      text-align: center;
      padding: 0.5rem 0 0.5rem 0.75rem;

    }

    &-no {
      text-align: center;
      padding: 0.5rem;
    }

    &-pos {
      text-align: center;
      padding: 0.5rem 0.75rem 0.5rem 0;
    }

    &-stat {
      text-align: center;
    }
  }

  @include tablet {
    padding: 0.75rem 0;
  }

  @include desktop {
    padding: 0.75rem 0;
    width: 50%;
  }
}
