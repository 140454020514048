@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.admin-main {
    background-color: $omr-grey;
    padding: 0 1rem 2rem;
  
    @include tablet {
      padding: 0 3rem 2rem;
    }
  
    @include desktop {
      padding: 0 4rem 2rem;
    }
  
    & .teamHeader {
      padding: 1rem 0 0;
    }
  }

.manageStats {

  &__return-link {
    @include flexbox;
    text-decoration: none;
    color: $black;

    &-back-icon {
      padding-right: 0.5rem;
    }
  }

  &__team-columns {
    @include desktop {
        @include flexbox(space-between, flex-start, row, nowrap, 3rem);

        &-team {
            width: 100%;
        }
    }
  }
}