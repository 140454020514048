@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.goalie-gaa-leaders {
  margin-bottom: 2.5rem;
  overflow-x: auto;
  max-height: 25rem;
  scrollbar-width: none;
  width: 100%;

  &__table {
    width: 100%;
    border-collapse: collapse;
    &-title {
      @include tableHeader(1.25rem);
      position: sticky;
      top: 0;
      z-index: 3;
    }
    &-headers th {
      @include columnheader;
      padding: 0.5rem 0;
      position: sticky;
      top: 2rem;
      background-color: $white;
      z-index: 3;

      @include tablet {
        font-size: 1.125rem;
      }
    }

    &-row {
      &-players:nth-of-type(odd) {
        background-color: $omr-grey;
      }
    }

    &-box {
      text-align: center;
      @include bigp;

      &-name {
        @include tablet {
          width: 40%;
        }
      }

      &-team {
        &-logo {
          height: 1.5rem;
          vertical-align: middle;
          padding: 0.25rem 0;
        }

        @include tablet {
          width: 12.5%;
        }
      }
      &-position {
        width: 2rem;

        @include tablet {
          width: 12.5%;
        }
      }

      &-games,
      &-ga &-gaa {
        width: 1.75rem;

        @include tablet {
          width: 12.5%;
        }
      }
    }
  }

  @include tablet {
    width: 48%;
  }

  @include desktop {
    width: 50%;
  }
}
