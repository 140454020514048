@use "../../styles/variables" as *;
@use "../../styles/mixins" as *;

.background {
  height: 90%;
  min-height: 90vh;
  position: relative;
  padding-bottom: 20rem;
  background-color: $omr-grey;
}

.dashboard {
  &__flex {
    @include desktop {
      @include flexbox(flex-start, flex-start);

      &-teams-games {
        width: 30%;
      }

      &-boxscores {
        width: 70%;
      }
    }
  }

  &__title {
    font-size: 1.75rem;
  }

  &__subtitle {
    font-size: 1.375rem;
  }

  &__link {
    display: block;
    text-decoration: none;
    color: $omr-darkblue;
    font-size: 1.125rem;
    padding: 0.25rem 0;
  }

  @include desktop {
    max-width: 1280px;
    margin: 0 auto;
  }
}
