@use "../../../styles/variables" as *;
@use "../../../styles/mixins" as *;

.hero {
  @include flexbox(flex-end, flex-end);
  position: relative;
  width: 100%;
  height: 22rem;
  background: url(../../../assets/images/games/Odd-Man-Rush-Hockey-League-Action.webp)
    no-repeat center/cover;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    position: absolute;
    margin: 1.5rem;
    max-width: 20rem;

    @include tablet {
      margin: 1.5rem 2rem;
      max-width: 25rem;
    }

    @include desktop {
      max-width: 30rem;
    }
  }

  &__h1 {
    @include h1;
    padding: 1.5rem;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 5px;
    margin: 0;
    text-align: center;

    @include tablet {
      text-align: right;
    }
  }

  @include tablet {
    margin: 0 3rem;
    height: 25rem;
  }

  @include desktop {
    width: 90%;
    height: 30rem;
    max-width: 1280px;
  }
}
